export class User {
  email: string
  firstName: string
  lastName: string
  cognitoId: string
  stripeCustomerId: string
  stripeSubscriptionId?: string
  stripeSubscriptionStatus?: string
  stripePriceId?: string

  constructor(
    email: string,
    firstName: string,
    lastName: string,
    cognitoId: string,
    stripeCustomerId: string,
    stripeSubscriptionId?: string,
    stripeSubscriptionStatus?: string,
    stripePriceId?: string,
  ) {
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.cognitoId = cognitoId
    this.stripeCustomerId = stripeCustomerId
    this.stripeSubscriptionId = stripeSubscriptionId
    this.stripeSubscriptionStatus = stripeSubscriptionStatus
    this.stripePriceId = stripePriceId
  }
}

export interface BiometricCredential {
  username: string
  password: string
}
