import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import TabsView from '../views/TabsView.vue'
import { useAuthStore } from '@/stores/auth'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'
import { menuController } from '@ionic/vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/auth/login',
  },
  {
    path: '/pricing',
    meta: { requiresAuth: true },
    component: () =>
      Capacitor.isNativePlatform()
        ? import('@/views/auth/LoginPage.vue')
        : import('@/views/PricingPage.vue'),
  },
  {
    path: '/checkoutSuccess',
    meta: { requiresAuth: true },
    component: () => import('@/views/CheckoutSuccessPage.vue'),
  },
  {
    path: '/profile',
    meta: { requiresAuth: true },
    component: () => import('@/views/ProfilePage.vue'),
  },
  {
    path: '/auth/login',
    component: () => import('@/views/auth/LoginPage.vue'),
  },
  {
    path: '/subscriptionExpired',
    component: () => import('@/views/InactiveSubscriptionPage.vue'),
  },
  {
    path: '/auth/signup',
    component: () => import('@/views/auth/SignupPage.vue'),
  },
  {
    path: '/auth/verify-email',
    component: () => import('@/views/auth/VerifyEmailPage.vue'),
  },
  {
    path: '/onboarding/add-income',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/AddIncomePage.vue'),
  },
  {
    path: '/onboarding/add-expenses',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/AddExpensesPage.vue'),
  },
  {
    path: '/onboarding/add-accounts',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/AddAccountsPage.vue'),
  },
  {
    path: '/onboarding/welcome',
    meta: { requiresAuth: true },
    component: () => import('@/views/onboarding/WelcomePage.vue'),
  },
  {
    path: '/auth/signup-success',
    component: () => import('@/views/auth/SignupSuccessPage.vue'),
  },
  {
    path: '/onboarding/start-your-budget',
    component: () => import('@/views/onboarding/StartYourBudgetPage.vue'),
  },
  {
    path: '/tabs',
    component: TabsView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/tabs/balance',
      },
      {
        path: 'balance',
        component: () => import('@/views/BalancePage.vue'),
      },
      {
        path: 'category/:id',
        component: () => import('@/views/CategoryPage.vue'),
      },
      {
        path: 'transactions',
        component: () => import('@/views/TransactionsPage.vue'),
      },
      {
        path: 'accounts',
        component: () => import('@/views/AccountsPage.vue'),
      },
    ],
  },
  {
    path: '/close-browser',
    component: () => import('@/views/CloseBrowserPage.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  if (await menuController.isOpen()) {
    menuController.close()
  }
  if (to.path == '/onboarding/add-accounts') {
    return next()
  }
  if (
    to.meta.requiresAuth &&
    !authStore.isLoggedIn &&
    !to.query.hasOwnProperty('session_id') &&
    !to.query.hasOwnProperty('token')
  ) {
    next({ path: '/auth/login', query: { redirect: to.fullPath } })
  } else if (to.meta.requiresAuth) {
    if (to.path == '/pricing') {
      if (authStore.hasActiveSubscription) {
        next('/onboarding/add-accounts')
      }
      if (Capacitor.isNativePlatform()) {
        await Browser.open({
          url: `${import.meta.env.VITE_APP_URL}/pricing?token=${
            authStore.token
          }&mobile=true`,
          presentationStyle: 'popover',
        })
        Browser.addListener('browserFinished', async () => {
          let retries = 3
          while (retries > 0) {
            await authStore.getUser()
            if (authStore.hasActiveSubscription) {
              authStore.onboardingComplete = true
              router.push('/onboarding/add-accounts')
              return
            }
            retries--
            await new Promise((resolve) => setTimeout(resolve, 5000))
          }
          router.push('/tabs/balance')
        })
        return
      }
      return next()
    }
    return next()
  } else {
    next()
  }
})

export default router
