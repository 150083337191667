import { defineStore } from 'pinia'
import { ref } from 'vue'
import { DefineComponent } from 'vue'

interface ModalState {
  isOpen: boolean
  modalContent: DefineComponent | null
  modalTitle: string
  modalProps: Record<string, any>
}

export const useModalStore = defineStore('modal', () => {
  const isOpen = ref<ModalState['isOpen']>(false)
  const modalContent = ref<ModalState['modalContent']>(null)
  const modalTitle = ref<ModalState['modalTitle']>('')
  const modalProps = ref<ModalState['modalProps']>({})
  const onCloseCallback = ref<((params: any) => void) | null>(null) // params help in situation like confirmation modal or when we want to pass some data during modal close

  const openModal = (
    content: DefineComponent,
    title = '',
    props: Record<string, any> = {},
    callback: ((params: any) => void) | null = null,
  ) => {
    modalContent.value = content
    modalTitle.value = title
    modalProps.value = props
    isOpen.value = true
    onCloseCallback.value = callback
  }

  const closeModal = (params: any = null) => {
    isOpen.value = false
    modalContent.value = null
    modalProps.value = {}
    modalTitle.value = ''
    if (onCloseCallback.value) {
      onCloseCallback.value(params)
      onCloseCallback.value = null
    }
  }

  return { isOpen, modalContent, modalTitle, openModal, modalProps, closeModal }
})
